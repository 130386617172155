import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Session } from "../models";
import { Button } from "./Common/Button";

interface Props {
  session: Session;
  handleClick: Function;
}
const SelectConsultType = ({ session, handleClick }: Props) => {
  const [consultType, setConsultType] = useState(session.consult);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const updateButtonIsActive = useCallback((): void => {
    setIsButtonActive(consultType != null);
  }, [consultType]);

  const handleChange = (e: any): void => {
    const { value } = e.target;
    setConsultType(value);
    updateButtonIsActive();
  };

  const validate = () => {
    if (!isEmpty(consultType)) {
      handleClick(consultType);
    }
  };

  useEffect(() => {
    updateButtonIsActive();
  });

  return (
    <>
      <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
        <div className="cs-chkin-form-content step col-xs-11">
          <h3 className="cs-chkin-form-step-heading">Select a consult type</h3>
          <div id="regular_consult_options">
            <label
              htmlFor="standard_consult"
              className="cursor-pointer d-block"
              style={{ marginBottom: 32 }}
            >
              <span className="radio-consult-type d-flex">
                <input
                  id="standard_consult"
                  type="radio"
                  name="consult"
                  data-req="true"
                  className="valid"
                  data-type="standard"
                  value="standard"
                  defaultChecked={"standard" === session.consult?.name}
                  onClick={handleChange}
                />
                <label id="standard_consult_label" htmlFor="standard_consult">
                  Standard telehealth consult ($
                  <span className="standard">{session.standardPrice}</span>)
                </label>
              </span>

              <span className="description d-block">
                Up to 10 minutes in length. Please note that if a short consult
                goes longer than 10 minutes, you will be charged an extra $10.
              </span>
            </label>

            <label
              htmlFor="priority_consult"
              className="cursor-pointer d-block"
              style={{ marginBottom: 24 }}
            >
              <span className="radio-consult-type d-flex">
                <input
                  id="priority_consult"
                  type="radio"
                  name="consult"
                  data-req="true"
                  className="valid"
                  data-type="priority"
                  value="priority"
                  defaultChecked={"priority" === session.consult?.name}
                  onClick={handleChange}
                />
                <label id="priority_consult_label" htmlFor="priority_consult">
                  Priority consult ($
                  <span className="priority">{session.priorityPrice}</span>)
                </label>
              </span>

              <span className="description d-block">
                Get ahead of the queue to minimise waiting time.
              </span>
            </label>
          </div>
          <div className="blue-content-box">
            <img
              src="images/svg/Icon_Information_blue.svg"
              className="align-self-start mr-2"
              alt="information icon"
            />
            <div className="body-text black-80">
              Please note Our Sage GPs do not provide prescriptions for
              controlled, addictive, and / or S8 medications.
            </div>
          </div>

          <div className="form-group">
            <button
              className="btn btn-lg btn-primary js-btn-prev d-none"
              type="button"
              title="Prev"
            ></button>
            <Button
              label="Next"
              handleClick={validate}
              isLoading={false}
              isActive={isButtonActive}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectConsultType;
