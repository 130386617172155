import { useState, useCallback, useEffect } from "react";

interface UseHttpOptions {
  method?: "GET" | "DELETE" | "POST" | "PUT";
  queryString?: string;
  body?: Record<string, any>;
  autoFetch?: boolean;
}

interface UseHttpResult<T> {
  data: T | null;
  isLoading: boolean;
  isError: boolean;
  fetchData: () => void;
  clearData: () => void;
}

export const useHttp = <T>(
  url: string,
  options: UseHttpOptions = {}
): UseHttpResult<T> => {
  const {
    method = "GET",
    queryString = "",
    body = null,
    autoFetch = false
  } = options;

  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      // Construct the URL with the query string if provided
      const requestUrl = queryString ? `${url}?${queryString}` : url;
      const response = await fetch(requestUrl, {
        method,
        headers: body ? { "Content-Type": "application/json" } : {},
        body: body ? JSON.stringify(body) : null
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Check if there's content to parse
      const contentLength = response.headers.get("Content-Length");
      if (response.status !== 204 && contentLength !== "0") {
        const result = await response.json();
        setData(result);
      } else {
        setData(null); // No content available
      }
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  }, [url, method, queryString, body]);

  const clearData = () => {
    setData(null);
  };

  useEffect(() => {
    if (autoFetch) {
      fetchData();
    }
  }, [fetchData, autoFetch]);

  return { data, isLoading, isError, fetchData, clearData };
};
