interface props {
  handleNextclick: Function;
}
export const ConsultComplete = ({ handleNextclick }: props) => {
  return (
    <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
      <div className="cs-chkin-form-content col-xs-11 p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 24
          }}
        >
          <img
            width="512"
            className="align-self-center"
            src="images/Image_consultComplete.png"
            alt="complete"
          />
        </div>
        <div className="wt-room-heading mb-4">Thank you!</div>
        <div className="body-text mb-5 text-center">
          Your telehealth consult has now ended.
        </div>

        <div className="form-group col-sm-12">
          <button
            className="btn btn-lg btn-block btn-solid js-btn-next active"
            id="payment_next"
            type="button"
            onClick={() => handleNextclick()}
          >
            Start a new consult
          </button>
          <button
            className="btn btn-lg btn-block btn-outline js-btn-next"
            id="payment_next"
            type="button"
            onClick={() => handleNextclick()}
          >
            Close
          </button>
        </div>

        <div
          className="thank-you-logo"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img src="images/svg/Image_OurSage_Logo.svg" alt="logo" />
        </div>
      </div>
    </div>
  );
};
