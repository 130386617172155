import { ConsultType } from "../models";

export const ConsultTypes = {
  standard: {
    name: "standard",
    itemNumber: 51803,
    order: 1,
    price: 45,
    label: "Standard telehealth consult"
  },
  long: {
    name: "long",
    itemNumber: 51804,
    order: 2,
    price: 55,
    label: "Long telehealth consult"
  },
  priority: {
    name: "priority",
    itemNumber: 51805,
    order: 3,
    price: 65,
    label: "Priority consult"
  }
};

export const ConsultTypesMap = new Map(Object.entries(ConsultTypes));

export const ConsultTypesArray: ConsultType[] = [
  {
    name: "standard",
    itemNumber: 51803,
    order: 1,
    price: 45,
    label: "Standard telehealth"
  },
  {
    name: "long",
    itemNumber: 51804,
    order: 2,
    price: 55,
    label: "Long telehealth"
  },
  {
    name: "priority",
    itemNumber: 51805,
    order: 3,
    price: 65,
    label: "Priority"
  }
];
