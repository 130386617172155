import React, { createContext, useState, useContext, ReactNode } from "react";
import { Alert } from "../models";
interface AlertContextType {
	alert: Alert | null;
	showAlert: (alert: Alert) => void;
	clearAlert: () => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlertContext = (): AlertContextType => {
	const context = useContext(AlertContext);
	if (!context) {
		throw new Error("useAlertContext must be used within an AlertProvider");
	}
	return context;
};

interface AllertProviderProps {
	children: ReactNode;
}

export const AlertProvider: React.FC<AllertProviderProps> = ({ children }) => {
	const [alert, setAlert] = useState<Alert | null>(null);
	const showAlert = (alert: Alert) => setAlert(alert);
	const clearAlert = () => setAlert(null);

	return (
		<AlertContext.Provider
			value={{
				alert,
				showAlert,
				clearAlert,
			}}
		>
			{children}
		</AlertContext.Provider>
	);
};
