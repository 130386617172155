import { Box, Modal } from "@mui/material";
import React from "react";
import { ModalStyle } from "../constants";

interface Props {
  open: boolean;
  handleClose: (event: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}

const NoMatchingDetailsModal = React.memo(({ open, handleClose }: Props) => {
  const buttonClick = (value: string) => {
    handleClose({
      target: {
        name: "noMatchingDetails",
        value: value,
        checked: value
      }
    } as any);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <div className="text-center" style={{ marginBottom: "52px" }}>
          <img src="images/svg/Icon_Blue_Alert.svg" alt="alert" />
        </div>
        <h3 id="singleNameModalLabel" className="modal-heading mb-4">
          No matching details found
        </h3>
        <div className="modal-text">
          Are your details correct? Please check your details or proceed as a
          new patient
        </div>
        <div className="justify-content-center" style={{ display: "grid" }}>
          <div className="text-center pb-3">
            <button
              className="btn btn-block btn-solid active btn-singleName-yes"
              style={{ padding: "12px 24px", marginTop: "24px" }}
              type="button"
              onClick={() => buttonClick("")}
            >
              Back to edit
            </button>
          </div>
          <div className="text-center">
            <button
              className="btn btn-block modal-btn-outline btn-singleName-no"
              type="button"
              onClick={() => buttonClick("newpatient")}
            >
              Restart as new patient
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
});

export default NoMatchingDetailsModal;
