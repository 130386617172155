export const CSConfig = {
  stepsIndicator: document.querySelectorAll(".cs-form-progress-indicator-bar"),
  stepsBar: document.querySelector(".cs-chkin-form-progress"),
  stepsForm: document.querySelector(".cs-chkin-form"),
  stepsFormTextareas: document.querySelectorAll(".cs-chkin-form-textarea"),
  stepFormPanelClass: "cs-chkin-form-panel",
  stepFormPanels: document.querySelectorAll(".cs-chkin-form-panel"),
  stepPrevBtnClass: "js-btn-prev",
  stepNextBtnClass: "js-btn-next",
  vcodeInputForm: document.querySelector(".vcode-input-form"),
  vcodeSegments: document.getElementsByName("vcode"),
  date: document.querySelector(".date"),
  expiry: document.querySelector(".expiry"),
  npHeading: "New Patient",
  exHeading: "Existing Patient",
  stepPatientType: 0,
  stepVUser: 1,
  stepSendCode: 2,
  stepVCode: 3,
  stepContactDetails: 4,
  stepEmerContact: 5,
  stepMedicare: 6,
  stepVoucher: 7,
  stepConsultType: 8,
  stepContactNo: 9,
  stepSummary: 10,
  stepPayment: 11,
  stepWaitingRoom: 12,
  stepThankYou: 13,

  numSteps: 12,
};
