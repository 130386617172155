import "./AlertBar.scss";
import { useAlertContext } from "../../contexts";

export const AlertBar = () => {
  const { alert } = useAlertContext();
  if (!alert) {
    return null;
  }
  return (
    <>
      <div
        id="waiting-bar-mobile"
        className={`waiting-time-mobile waiting-time ${
          alert.alertType === "error" ? "unavailable" : ""
        }`}
      >
        <div className="inner-wait-mobile columnGap-2">
          {alert.alertType === "error" ? (
            <img
              src="images/svg/Icon_Error_Red.svg"
              width="32"
              height="32"
              className="warning_icon align-self-start"
              alt="warning icon"
            />
          ) : (
            <img
              src="images/svg/Icon_Waiting.svg"
              width="32"
              height="32"
              className="wait_icon"
              alt="waiting icon"
            />
          )}
          <div className="wait_text">{alert.message}</div>
        </div>
      </div>

      <div
        id="waiting-bar-desktop"
        className={`waiting-time-desktop waiting-time ${
          alert.alertType === "error" ? "unavailable" : ""
        }`}
      >
        <div className="align-self-center inner-waiting-time columnGap-2">
          {alert.alertType === "error" ? (
            <img
              src="images/svg/Icon_Error_Red.svg"
              width="32"
              height="32"
              className="warning_icon align-self-start"
              alt="warning icon"
            />
          ) : (
            <img
              src="images/svg/Icon_Waiting.svg"
              width="32"
              height="32"
              className="wait_icon"
              alt="waiting icon"
            />
          )}
          <div className="wait_text">{alert.message}</div>
        </div>
      </div>
    </>
  );
};
