import {
  isValid,
  parse,
  format,
  isAfter,
  startOfToday,
  getDate,
  isBefore
} from "date-fns";
import isEmpty from "lodash/isEmpty";

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isDateValid = (dateString: string): boolean => {
  if (isEmpty(dateString)) {
    return false;
  }
  const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());

  if (isValid(parsedDate) && format(parsedDate, "dd/MM/yyyy") === dateString) {
    return true;
  }
  return false;
};

export const isDateAfterToday = (date: Date): boolean => {
  const today = startOfToday();
  return isAfter(date, today);
};

export const isDateBeforeToday = (date: Date): boolean => {
  const today = startOfToday();
  return isBefore(date, today);
};

export const isDateStringAfterToday = (dateString: string) => {
  if (isEmpty(dateString)) {
    return false;
  }
  const date = parse(dateString, "dd/MM/yyyy", new Date());
  return isDateAfterToday(date);
};

export const isDateDOBFormat = (dateString: string): boolean => {
  if (isEmpty(dateString)) {
    return false;
  }
  const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
  return isValid(parsedDate);
};

export const isValidMobileNo = (value: string): boolean => {
  const regex = new RegExp(/^\d{10}$/);
  return regex.test(value);
};

export const isValidEmail = (value: string): boolean => {
  const regex = new RegExp(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
  return regex.test(value);
};

export const isValidVerificationCode = (value: string): boolean => {
  const regex = new RegExp(/^\d{6}$/);
  return regex.test(value);
};

export const isValidPostcode = (value: string): boolean => {
  if (value === "") {
    return false;
  }
  const regex = new RegExp(/^\d{4}$/);
  return regex.test(value);
};

export const isDigit = (value: string): boolean => {
  const regex = new RegExp(/^\d{1}$/);
  return regex.test(value);
};

export const isCharacter = (value: string): boolean => {
  const regex = new RegExp(/^[a-zA-Z]{1}$/);
  return regex.test(value);
};

export const isValidMedicareNoOld = (value: string) => {
  const regex = new RegExp(/^\d{10}$/);
  const result = regex.test(value);
  return result;
};

const medicareWeights = [1, 3, 7, 9, 1, 3, 7, 9];
export const isValidMedicareNo = (value: string) => {
  if (value.length !== 10) {
    return false;
  }
  const matches = value.match(/^([2-6]\d{7})(\d)/);
  if (!matches) {
    return false;
  }

  const base = matches[1];
  const checkDigit = matches[2];
  let sum = 0;
  medicareWeights.forEach(
    (weight, i) => (sum += parseInt(base[i], 10) * weight)
  );
  return sum % 10 === parseInt(checkDigit, 10);
};

export const isValidMedicareLineNo = (value: string) => {
  const regex = new RegExp(/^\d{1}$/);
  return regex.test(value);
};

export const isValidExpiry = (expiry: string): boolean => {
  if (!expiry) {
    return false;
  }
  const dateString = `01/${expiry.replace(/ /g, "")}`;
  const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());

  if (isValid(parsedDate) && format(parsedDate, "dd/MM/yyyy") === dateString) {
    return true;
  }
  return false;
};

export const isExpiryBeforeToday = (expiry: string) => {
  if (isEmpty(expiry)) {
    return false;
  }
  const today = getDate(new Date());
  const dateString = `${today}/${expiry.replace(/ /g, "")}`;
  const date = parse(dateString, "dd/MM/yyyy", new Date());
  return isDateBeforeToday(date);
};

export const isValidIHINo = (value: string) => {
  const regex = new RegExp(/^\d{16}$/);
  return regex.test(value);
};

export function isMedicareNoValid(medicareNo: string) {
  // First character must be betwee 2 and 6
  //(digit 1) + (digit 2 * 3) + (digit 3 * 7) + (digit 4 * 9) + (digit 5) + (digit 6 * 3) + (digit 7 * 7) + (digit 8 * 9))
  let isValid = false;

  if (medicareNo && medicareNo.length === 10) {
    const matches = medicareNo.match(/^([2-6]\d{7})(\d)/);
    if (!matches) {
      return false;
    }

    const base = matches[1];
    const checkDigit = matches[2];
    const weights = [1, 3, 7, 9, 1, 3, 7, 9];

    let sum = 0;
    for (let i = 0; i < weights.length; i++) {
      sum += parseInt(base[i], 10) * weights[i];
    }

    isValid = sum % 10 === parseInt(checkDigit, 10);
  }
  return isValid;
}

export const getValidDatePart = (str: string, maximum: number): string => {
  if ("00" === str || "0" !== str.charAt(0)) {
    let val = parseInt(str);
    if (isNaN(val) || val < 1) {
      return "";
    }

    if (val > maximum) {
      return val.toString().charAt(0);
    }

    if (
      val > parseInt(maximum.toString().charAt(0)) &&
      val.toString().length === 1
    ) {
      return "";
    }

    str = "" + val;
  }

  return str;
};
