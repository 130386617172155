import React from "react";

type Props = {
  registrationType: Function;
};

const RegistrationType = React.memo(({ registrationType }: Props) => {
  return (
    <>
      <div className="cs-chkin-form-panel js-active" id="regTypePanel">
        <div className="cs-chkin-form-content col-xs-11">
          <h2 className="cs-chkin-form-step-heading mt-3 mb-5">
            Is this the patient's first time with
            <span className="cs-chkin-form-step-heading bold">
              &nbsp;Our Sage?
            </span>
          </h2>
          <button
            className="btn btn-block btn-selection np col-sm-12"
            data-np="1"
            id="new_patient"
            type="button"
            onClick={() => registrationType(1)}
          >
            New patient
            <img
              src="images/svg/cs_blue_tick.svg"
              className="ml-auto"
              alt="tick"
            />
          </button>
          <button
            className="btn btn-block btn-selection np col-sm-12 mb-0"
            data-np="2"
            id="existing_patient"
            type="button"
            onClick={() => registrationType(2)}
          >
            Existing patient
            <img
              src="images/svg/cs_blue_tick.svg"
              className="ml-auto"
              alt="tick"
            />
          </button>
          <button
            className="btn btn-lg btn-primary js-btn-prev d-none"
            type="button"
            title="Prev"
          ></button>
        </div>
      </div>
    </>
  );
});

export default RegistrationType;
