import { useCallback, useEffect, useState } from "react";
import { ContactDetailsForm, PatientDetails, Session } from "../models";
import { InputControl } from "./Common";
import { Button } from "./Common/Button";
import { isEmpty } from "lodash";
import { isValidPostcode, sendGAEvent, ValidatePostcode } from "../helpers";
import React from "react";
import { EXISTING_PATIENT, InitialContactDetailsForm } from "../constants";

interface Props {
  session: Session;
  patient: PatientDetails;
  handleNextClick: Function;
}
const ContactDetails = React.memo(
  ({ session, patient, handleNextClick }: Props) => {
    const [contactDetailsForm, setContactDetailsForm] =
      useState<ContactDetailsForm>(InitialContactDetailsForm);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [focusName, setFocusName] = useState("");
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e: any): void => {
      const { name, value } = e.target;
      const newContactDetails = {
        ...contactDetailsForm,
        [name]: value,
        isPostcodeAuthenticated:
          name === "pcode"
            ? undefined
            : contactDetailsForm.isPostcodeAuthenticated
      };
      setContactDetailsForm(newContactDetails);
      updateButtonIsActive(newContactDetails);
      setFocusName("");
      if (
        name === "pcode" &&
        isValidPostcode(value) &&
        (value !== contactDetailsForm.pcode ||
          !contactDetailsForm.isPostcodeAuthenticated)
      ) {
        callValidatePostcode(value);
      }
    };

    const validAddress = (contactDetailsForm: ContactDetailsForm): boolean => {
      return !isEmpty(contactDetailsForm.address);
    };

    const validSuburb = (contactDetailsForm: ContactDetailsForm): boolean => {
      return !isEmpty(contactDetailsForm.suburb);
    };

    const validPostcode = useCallback(
      (contactDetailsForm: ContactDetailsForm): boolean => {
        return (
          (isValidPostcode(contactDetailsForm.pcode) &&
            contactDetailsForm.isPostcodeAuthenticated) ||
          false
        );
      },
      []
    );

    const updateButtonIsActive = useCallback(
      (contactDetailsForm: ContactDetailsForm): void => {
        const isButtonActive =
          validAddress(contactDetailsForm) &&
          validSuburb(contactDetailsForm) &&
          validPostcode(contactDetailsForm) &&
          !(contactDetailsForm.isPostcodeAuthenticated === false);
        setIsButtonActive(isButtonActive);
      },
      [validPostcode]
    );

    const validate = () => {
      let errorsExist = false;
      setFocusName("");
      setValidationErrors([]);

      const validationErrors: string[] = [];
      const addError = (fieldName: string) => {
        if (!errorsExist) {
          setTimeout(() => {
            setFocusName(fieldName);
          });
        }
        validationErrors.push(fieldName);
        errorsExist = true;
      };

      if (!validAddress(contactDetailsForm)) {
        addError("address");
      }

      if (!validSuburb(contactDetailsForm)) {
        addError("suburb");
      }

      if (!validPostcode(contactDetailsForm)) {
        addError("pcode");
      }

      if (errorsExist) {
        setTimeout(() => setValidationErrors(validationErrors));
      } else if (contactDetailsForm.isPostcodeAuthenticated == null) {
        callValidatePostcode(contactDetailsForm.pcode);
      } else if (contactDetailsForm.isPostcodeAuthenticated) {
        sendGAEvent({
          event: "contact_details",
          step: 6,
          step_name: "Contact Details",
          user_id: patient.maskedEmail,
          extra: {
            suburb: contactDetailsForm.suburb,
            postcode: contactDetailsForm.pcode
          }
        });
        handleNextClick(contactDetailsForm);
      }
    };

    const callValidatePostcode = async (postcode: string) => {
      const result = await ValidatePostcode(postcode, setIsLoading);
      setContactDetailsForm({
        ...contactDetailsForm,
        isPostcodeAuthenticated: result
      });
    };

    useEffect(() => {
      let isPostcodeAuthenticated = undefined;
      if (session.patientType === EXISTING_PATIENT) {
        isPostcodeAuthenticated = patient.pcode != null;
      }
      setContactDetailsForm({
        address: patient.address,
        suburb: patient.suburb,
        pcode: patient.pcode,
        isPostcodeAuthenticated
      });
    }, [session, patient]);

    useEffect(() => {
      if (contactDetailsForm.isPostcodeAuthenticated === false) {
        setValidationErrors(["pcode"]);
        return;
      }
      if (
        isValidPostcode(contactDetailsForm.pcode) &&
        contactDetailsForm.isPostcodeAuthenticated == null
      ) {
        callValidatePostcode(contactDetailsForm.pcode);
        return;
      }
      if (contactDetailsForm.isPostcodeAuthenticated) {
        updateButtonIsActive(contactDetailsForm);
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactDetailsForm, updateButtonIsActive]);

    return (
      <>
        <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
          <div className="cs-chkin-form-content step col-xs-11">
            <h3 className="cs-chkin-form-step-heading">
              Patient's current contact details
            </h3>

            <InputControl
              type="text"
              label="Home Address*"
              name="address"
              value={contactDetailsForm.address}
              onChange={handleChange}
              focus={focusName === "address"}
            />

            <InputControl
              type="text"
              label="Suburb*"
              name="suburb"
              value={contactDetailsForm.suburb}
              onChange={handleChange}
              focus={focusName === "suburb"}
              error={validationErrors.includes("suburb")}
            />

            <div className="col-xs-7" style={{ padding: 0 }}>
              <InputControl
                type="text"
                label="Postcode*"
                name="pcode"
                value={contactDetailsForm.pcode}
                onChange={handleChange}
                triggerChangeOnKeyup={true}
                focus={focusName === "pcode"}
                error={validationErrors.includes("pcode")}
              />
            </div>
            {contactDetailsForm.isPostcodeAuthenticated && (
              <div id="valid_postcode" className="col-xs-5 pt-4 text-success">
                ✔
              </div>
            )}
            {contactDetailsForm.isPostcodeAuthenticated === false && (
              <div id="invalid_postcode" className="col-xs-5 pt-4 text-danger">
                x
              </div>
            )}

            <div className="form-group">
              <button
                className="btn btn-lg btn-primary js-btn-prev d-none"
                type="button"
                title="Prev"
              ></button>
              <Button
                label="Next"
                handleClick={validate}
                isLoading={isLoading}
                isActive={isButtonActive}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default ContactDetails;
