import {
  ConfirmConsult,
  ContactDetailsForm,
  EmergencyNextOfKinForm,
  MedicareDetailsForm,
  SendVerificationCodeForm,
  Session,
  ValidatePatientForm
} from "../models";

export const InitialSession: Session = {
  cid: -1,
  patientType: 0,
  longPrice: 55,
  priorityPrice: 65,
  standardPrice: 45,
  checkinMessage: "",
  waitText: "",
  feeText:
    "The consult fee will be held as a pre-authorised transaction. Final payment is processed after the consult.",
  verificationCode: "",
  vopt: "M",
  mob: "",
  codeCount: 0,
  authorised: false,
  sessionId: crypto.randomUUID(),
  consult: undefined,
  contactNumber: "",
  email: "",
  agreeTCs: false,
  consent: false,
  acknowledge: false,
  queueStatus: -3
};

export const InitialValidatePatientForm: ValidatePatientForm = {
  fName: "",
  lName: "",
  singleName: false,
  dob: "",
  mobileNo: "",
  email: "",
  gender: "",
  gender_identity: ""
};

export const InitialSendVerificationCodeForm: SendVerificationCodeForm = {
  mobileNo: "",
  email: "",
  maskedMobile: "",
  maskedEmail: "",
  vopt: "M",
  patientExists: false,
  verificationCode: ""
};

export const InitialContactDetailsForm: ContactDetailsForm = {
  address: "",
  suburb: "",
  pcode: "",
  isPostcodeAuthenticated: undefined
};

export const InitialEmergencyNextOfKinForm: EmergencyNextOfKinForm = {
  emer_fname: "",
  emer_lname: "",
  emer_contact_no: "",
  emer_rel: "",
  emer_tick: false,
  kin_fname: "",
  kin_lname: "",
  kin_contact_no: "",
  kin_rel: ""
};

export const InitialMedicareDetailsForm: MedicareDetailsForm = {
  medicareNo: "",
  medicarelineno: "",
  exp_medi: "",
  noMedicare: false,
  IHI: ""
};

export const InitialConfirmConsult: ConfirmConsult = {
  name: "",
  mobileNo: "",
  email: "",
  consultType: "",
  consultLabel: "",
  price: 0,
  agreeTCs: false,
  consent: false,
  acknowledge: false
};
