export const isDev = true;

export const sessionData = {
  base_url: isDev
    ? "https://automedsystems-dev.com.au/online/omh"
    : "https://automedsystems.com.au/online/omh",
  checkin_message: "",
  cid: isDev ? 2 : 5088,
  singleName: false,
  rtype: 1, // 1 - New, 2 - Existing
  ortype: 1, // 1 - New, 2 - Existing,
  vcode: "",
  pinfo: { prn: null },
  auth: false,
  code: "",
  mob: "",
  codecount: 0,
  docg: "",
  docid: 0,
  reqConsultType: 0,
  ctype: 1,
  activeStep: 0,
  qstat: -3,
  qstatstr: "",
  hasfm: 0,
  display_name: "",
  IHI: "",
  contact_number: "",
  safeReload: false,
  consult_type: "standard",
  consult_price: null,
  appointment_type: null,
  checkin_type: null,
  consult_label: null,

  voucher: {
    sessionId: "",
    correlationId: crypto.randomUUID(),
    voucherStatus: false,
    consumer_id: "",
    voucher_ref_guid: "",
    code: "",
    isPromo: false,
  },
};
