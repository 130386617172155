import React from "react";
import "./Header.scss";

interface Props {
  heading: string;
  completedStepPercent: number;
  remainingStepPercent: number;
  enablePrevButton: boolean;
  prevButtonClick: Function;
  closeButtonClick: Function;
  enableCloseButton: boolean;
}
const Header = React.memo(
  ({
    heading,
    completedStepPercent,
    remainingStepPercent,
    enablePrevButton,
    prevButtonClick,
    closeButtonClick,
    enableCloseButton
  }: Props) => {
    return (
      <>
        <div className="sticky-header" id="fixed_header">
          <div className="main-header">
            <div
              className="col-lg-1 col-xs-1 p-0 ml-4 text-right"
              id="back_button"
            >
              <button
                className="btn-prev"
                name="btn_prev"
                style={{ cursor: "default" }}
                type="button"
                onClick={() => {
                  if (enablePrevButton) {
                    prevButtonClick();
                  }
                }}
              >
                {enablePrevButton && (
                  <img
                    src="images/svg/Icon_Nav_back_gray_desktop.svg"
                    width="10.85"
                    height="18.95"
                    id="prev_button"
                    alt="grey back button"
                  />
                )}
                {!enablePrevButton && (
                  <img
                    src="images/svg/Icon_Nav_back_light_gray_desktop.svg"
                    width="10.85"
                    height="18.95"
                    id="prev_light_button"
                    alt="light grey back button"
                  />
                )}
              </button>
            </div>

            <div
              className="col-lg-10 col-xs-10 d-flex text-center align-items-center"
              id="logo_heading"
            >
              <div
                className="d-flex justify-content-center"
                style={{ flex: 2 }}
              >
                <img
                  src="images/svg/Icon_OurSageLogo.svg"
                  id="ourSage_logo_mobile"
                  alt="logo"
                  style={{ height: 20.57 }}
                />
                <img
                  src="images/svg/Icon_OurSageLogo.svg"
                  id="ourSage_logo_tablet"
                  alt="logo"
                  style={{ height: 25.71 }}
                />
              </div>
              <div className="form-heading" style={{ flex: 4 }}>
                {heading}
              </div>
              <div style={{ flex: 2 }}></div>
            </div>

            <div
              className="col-lg-1 col-xs-1 p-0 mr-3 text-left"
              id="close_button"
            >
              {enableCloseButton && (
                <button
                  className="btn-close"
                  type="button"
                  onClick={() => closeButtonClick()}
                >
                  <img
                    src="images/svg/Icon_Nav_X_gray_desktop.svg"
                    width="17.58"
                    height="17.58"
                    id="exit_button"
                    alt="close button"
                  />
                </button>
              )}
            </div>
          </div>

          <div className="cs-form-progress-indicator">
            <div
              id="bar-completed"
              className="cs-form-progress-indicator-bar active"
              style={{ width: `${completedStepPercent}%` }}
            ></div>
            <div
              id="bar-remaining"
              className="cs-form-progress-indicator-bar"
              style={{ width: `${remainingStepPercent}%` }}
            ></div>
          </div>
        </div>
      </>
    );
  }
);

export default Header;
