import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { ModalStyle } from "../constants";
import { sendGAEvent } from "../helpers";

interface Props {
  onChange: Function;
}
const EmergencyPrompt = React.memo(({ onChange }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const emergencyClick = (value: boolean) => {
    sendGAEvent({
      event: "medical_emergency",
      step: 1,
      step_name: "Medical Emergency",
      extra: {
        medical_emergency: value
      }
    });
    setOpenModal(value);
    if (!value) {
      onChange();
    }
  };
  return (
    <>
      <div
        className="cs-chkin-form-panel js-active"
        data-animation="fadeIn"
        id="emergencyPanel"
      >
        <div className="col-11 column">
          <img src="images/svg/Icon_Blue_Warning.svg" alt="warning" />

          <div className="large-heading">Is this a medical emergency?</div>

          <div className="blue-content-box-extra-padding">
            <div className="bold-sub-heading black-80" id="emergencyHeading">
              Are you experiencing any of the following?
            </div>
            <ul className="body-text-off-black">
              <li>Chest pain</li>
              <li>Breathing difficulties</li>
              <li>Bleeding</li>
              <li>Broken bones</li>
              <li>Severe pain</li>
              <li>Feeling faint</li>
              <li>Distress</li>
            </ul>
          </div>

          <div className="align-self-stretch">
            <button
              type="button"
              className="btn btn-lg btn-block btn-outline"
              data-emer="yes"
              data-toggle="modal"
              data-target="#emergencyModal"
              onClick={() => emergencyClick(true)}
            >
              Yes, I am experiencing these
            </button>
            <button
              type="button"
              className="btn btn-lg btn-block btn-solid active js-btn-next mt-3"
              data-emer="no"
              onClick={() => emergencyClick(false)}
            >
              No, I am not experiencing these
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div
            className="col-sm-12 text-center"
            style={{ marginBottom: "32px" }}
          >
            <p>
              <img src="images/svg/Icon_Blue_Alert.svg" alt="alert" />
            </p>
          </div>
          <h3 className="modal-heading mb-4">Go to Emergency</h3>

          <div className="d-flex flex-column justify-content-center">
            <div className="modal-text">
              Call 000 or head to the nearest emergency department.
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                className="btn modal-btn-outline btn-pincode-edit"
                type="button"
                onClick={() => setOpenModal(false)}
              >
                OK
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
});

export default EmergencyPrompt;
