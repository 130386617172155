import { useCallback, useEffect, useState } from "react";
import { InputControl } from "./Common";
import { PatientDetails, Session } from "../models";
import { isValidMobileNo, sendGAEvent } from "../helpers";
import { Button } from "./Common/Button";

interface Props {
  session: Session;
  patient: PatientDetails;
  handleClick: Function;
}
const ContactNumber = ({ session, patient, handleClick }: Props) => {
  const [contactNumber, setContactNumber] = useState(
    session.contactNumber || patient.mobileNo
  );
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [focusName, setFocusName] = useState("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleChange = (e: any): void => {
    const { value } = e.target;
    setContactNumber(value);
    updateButtonIsActive();
    setFocusName("");
  };

  const updateButtonIsActive = useCallback((): void => {
    setIsButtonActive(contactNumber != null);
  }, [contactNumber]);

  const validate = () => {
    let errorsExist = false;
    setFocusName("");
    setValidationErrors([]);

    const validationErrors: string[] = [];

    if (!isValidMobileNo(contactNumber)) {
      validationErrors.push("contact_mobile");
      errorsExist = true;
      setTimeout(() => {
        setFocusName("contact_mobile");
      });
    }

    if (errorsExist) {
      setTimeout(() => setValidationErrors(validationErrors));
    } else {
      sendGAEvent({
        event: "contact_number",
        step: 10,
        step_name: "Contact Number",
        user_id: patient.maskedEmail
      });
      handleClick(contactNumber);
    }
  };

  useEffect(() => {
    updateButtonIsActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactNumber]);

  return (
    <>
      <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
        <div className="cs-chkin-form-content step col-xs-11">
          <h3 className="cs-chkin-form-step-heading">
            What number would you like the doctor to call you on?
          </h3>
          <div>
            <InputControl
              type="text"
              label="Phone number*"
              name="contact_mobile"
              value={contactNumber}
              maxlength={10}
              onChange={handleChange}
              focus={focusName === "contact_mobile"}
              error={validationErrors.includes("contact_mobile")}
            />
          </div>

          <div className="form-group">
            <button
              className="btn btn-lg btn-primary js-btn-prev d-none"
              type="button"
              title="Prev"
            ></button>
            <Button
              label="Confirm Number"
              handleClick={validate}
              isLoading={false}
              isActive={isButtonActive}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactNumber;
