export enum Step {
  Emergency = 0,
  PatientType = 1,
  Patient = 2,
  SendCode = 3,
  VerifyCode = 4,
  ContactDetails = 5,
  EmerContact = 6,
  Medicare = 7,
  ConsultType = 8,
  ContactNo = 9,
  Confirm = 10,
  Payment = 11,
  WaitingRoom = 12,
  ConsultComplete = 13
}
