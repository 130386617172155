import { PatientDetails, Session } from "../models";
import { FormatName } from "../helpers";

interface props {
  session: Session;
  patient: PatientDetails;
  enableCancel: boolean;
  cancelClick: Function;
}

export const WaitingRoom = ({
  session,
  patient,
  enableCancel,
  cancelClick
}: props) => {
  const nextInQueue = session.queueStatus <= 1;

  return (
    <>
      <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
        <div className="cs-chkin-form-content step col-11 p-0 d-flex flex-column align-items-center rowGap-2">
          <img
            src={
              nextInQueue
                ? "images/Image_upNext.png"
                : "images/Image_InQueue.png"
            }
            width="512"
            id="wt_room_image"
            alt="in queue"
          />

          <div className="wt-room-heading" id="wt_room_heading">
            {nextInQueue ? "You are up next" : "You are in the queue"}
          </div>

          <div className="blue-content-box" style={{ marginBottom: 8 }}>
            <img
              className="align-self-start mr-3"
              src="images/svg/Icon_Information_blue.svg"
              alt="info icon"
            />
            <div className="body-text black-80">
              The GP will call you from <b>(02) 4607 3100</b>
              <div>
                Please be ready to take the telehealth call on the phone number
                below. The doctor will attempt to call you 3 times.
              </div>
            </div>
          </div>

          <div className="content-box rowGap-2" id="wt_room_details">
            <div className="wt-room-sub-heading">
              For:
              <span className="wt-room-information-thin" id="wt_room_name">
                {FormatName(patient)}
              </span>
            </div>

            <div className="wt-room-sub-heading">
              Phone number:
              <span className="wt-room-information-thin" id="wt_room_mobile">
                {session.contactNumber}
              </span>
            </div>

            <div className="wt-room-sub-heading">
              Email address:
              <span className="wt-room-information-thin" id="wt_room_email">
                {session.email}
              </span>
            </div>

            <div>
              <div className="sub-heading">Consult type:</div>
              <div
                className="wt-room-information-thin"
                id="wt_room_consult_type"
              >
                {`${session.consult?.label} ($${session.consult?.price})`}
              </div>
            </div>
          </div>

          <div className="form-group ml-1 mt-3">
            <button
              className="btn btn-lg btn-block btn-outline btn-cncl"
              id="finish"
              type="button"
              disabled={!enableCancel}
              onClick={() => (enableCancel ? cancelClick() : null)}
            >
              Cancel this consult
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
